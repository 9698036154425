import { Tabs } from 'antd';
import ConnexionForm from './connexion';
import InscriptionForm from './inscription';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const items = [
  {
    key: '1',
    label: `Inscription`,
    children: <InscriptionForm />
  },
  {
    key: '2',
    label: `Connexion`,
    children: <ConnexionForm />
  }
];

const Form = () => {
  const [activeTab, setActiveTab] = useState('1');
  const router = useRouter();

  const handleClick = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    //if (router.asPath.includes('/test')) {
      setActiveTab('2');
    //}
  }, []);
       
  return (
  <>
    <Tabs activeKey={activeTab} items={items} onChange={handleClick} />
    <style jsx global>{`
      .ant-tabs-nav-wrap {
        justify-content: center;
      }
      .ant-tabs-tab {
        font-size: 18px;
        font-weight: 500;
      }
    `}</style>
  </>
  )
    }

export default Form;
