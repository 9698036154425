import { Button, Form, Input } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { sessionState } from '../../recoil/email';
import { useRecoilState } from 'recoil';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { setCookie, destroyCookie } from 'nookies';

const ConnexionForm = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [session, setSession] = useRecoilState(sessionState);

  const screens = useBreakpoint();

  const onFinish = async (values) => {
    setIsLoading(true);
    const dataToSend = {
      email: values.email
    };
    //make Http POST request to backend /api/signup
    try {
      const response = await fetch('/api/signin', {
        method: 'POST',
        body: JSON.stringify(dataToSend),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        setSession(values.email);

        setCookie(null, 'email', JSON.stringify(values.email), {
          maxAge: 1 * 60 * 60,
          sameSite: 'None',
          secure: true
        });
      } else {
        setHasError(true);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="form__container">
      <div className="form__header">
        <h1>Formulaire de connexion</h1>
      </div>
      {hasError && (
        <div
          className="form__error"
          style={{
            padding: '0 8px',
            fontWeight: 500,
            color: 'red'
          }}
        >
          <p>
            Adresse électronique invalide, vous pouvez créer un compte en
            cliqiant sur "Inscription" ci dessus.
          </p>
        </div>
      )}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Adresse électronique"
          name="email"
          rules={[{ required: true, message: 'Champ obligatoire' }]}
          style={{
            maxWidth: !screens.sm ? '100%' : '50%'
          }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          style={{
            flex: '1 1 100%'
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{
              textTransform: 'uppercase',
              fontSize: 16,
              fontWeight: 700,
              padding: '0 120px'
            }}
          >
            Envoyer
          </Button>
        </Form.Item>
      </Form>
      <style jsx global>{`
        .form__header {
          padding: 8px;
        }
        .form__header h1 {
          font-size: 32px;
          font-weight: 500;
          color: #082268 !important;
          margin: 0;
        }
        .form__header p {
          font-size: 15px;
          color: #082268 !important;
        }

        .form__header p span {
          font-weight: 900;
        }
        .ant-form {
          display: flex;
          flex-wrap: wrap;
        }
        .ant-form > .ant-form-item {
          flex: 1 1 ${!screens.sm ? '100%' : '48%'};
          margin: 0 8px 16px;
        }
        label {
          color: #082268 !important;
          font-weight: 500;
        }

        .checkbox-title .ant-form-item-label label {
          font-size: 18px;
          color: #082268 !important;
          font-weight: 500;
        }
        .ant-input[type='text'],
        .ant-select-selector,
        .ant-picker {
          color: #082268 !important;
          border: 2px solid #082268 !important;
          border-radius: 4px !important;
          width: 100% !important;
        }

        .ant-form-item-has-error input[type='text'] {
          border: 2px solid #f5222d !important;
        }

        .ant-form-item-explain-error {
          display: none !important;
        }

        .ant-checkbox-group {
          display: flex;
          flex-direction: column;
        }

        .ant-checkbox-inner {
          border-radius: 50%;
          border: 2px solid #082268 !important;
          width: 20px;
          height: 20px;
        }
      `}</style>
    </div>
  );
};

export default ConnexionForm;
