import { Button, Checkbox, DatePicker, Form, Input, Radio, Select } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { countryList, licences, masters } from './_utils';
import { setCookie } from 'nookies';
import { useRecoilState } from 'recoil';
import { sessionState } from '../../recoil/email';
import { useState } from 'react';

const InscriptionForm = () => {
  const [form] = Form.useForm();
  const [hasError, setHasError] = useState(false);

  const [session, setSession] = useRecoilState(sessionState);

  const screens = useBreakpoint();

  const onFinish = async (values) => {
    const dataToSend = {
      email: values.email,
      data: values,
      signUpAt: new Date()
    };

    //make Http POST request to backend /api/signup
    const response = await fetch('/api/signup', {
      method: 'POST',
      body: JSON.stringify(dataToSend),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 409) {
      setHasError(true);
      return;
    }

    setSession(values.email);

    setCookie(null, 'email', JSON.stringify(values.email), {
      maxAge: 1 * 60 * 60,
      sameSite: 'None',
      secure: true
    });
  };

  return (
    <div className="form__container">
      <div className="form__header">
        <h1>Formulaire d’inscription</h1>
        {hasError && (
          <div
            className="form__error"
            style={{
              padding: '0 8px',
              fontWeight: 500,
              color: 'red'
            }}
          >
            <p>
              Cet email est déjà utilisé, vous pouvez vous connecter en cliquant sur "Connexion" ci dessus.
            </p>
          </div>
        )}
        <p>
          <span>L’inscription au webinaire est gratuite</span>, dès validation
          du formulaire d’inscription ci-dessous, vous recevrez un courrier
          électronique avec le lien de connexion pour assister à l’événement
          digital le <span>samedi 3 juin</span>.
        </p>
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="genre"
          label=""
          style={{
            flex: '1 1 100%'
          }}
        >
          <Radio.Group>
            <Radio value="female"> Mme. </Radio>
            <Radio value="male"> Mr. </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Prénom"
          name="prenom"
          rules={[{ required: true, message: 'Champ obligatoire' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Nom"
          name="nom"
          rules={[{ required: true, message: 'Champ obligatoire' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Téléphone mobile* + [indicatif pays] [Numéro]"
          name="telephone"
          rules={[{ required: true, message: 'Champ obligatoire' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Adresse électronique"
          name="email"
          rules={[{ required: true, message: 'Champ obligatoire' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Pays"
          name="pays"
          rules={[{ required: true, message: 'Champ obligatoire' }]}
        >
          <Select showSearch>
            {countryList.map(({ label, value }) => (
              <Select.Option key={label} value={value}>{label}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Date de naissance" name="dateNaissance">
          <DatePicker placeholder="" />
        </Form.Item>

        <Form.Item label="Dernier diplôme obtenu" name="diplome">
          <Input />
        </Form.Item>

        <Form.Item
          label="Nom de l’établissement scolaire/université actuel"
          name="etablissement"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Entreprise (si vous êtes déjà en activité)"
          name="entreprise"
          style={{
            flex: '1 1 100%'
          }}
        >
          <Input
            style={{
              maxWidth: !screens.sm ? '100%' : '49%'
            }}
          />
        </Form.Item>

        <p
          className="formation"
          style={{
            flex: '1 1 100%'
          }}
        >
          Formation recherchée
        </p>

        <Form.Item
          label="Programmes de licence"
          name="licences"
          valuePropName="checked"
          className="checkbox-title"
        >
          <Checkbox.Group>
            {licences.map((licence) => (
              <Checkbox key={licence} value={licence}>{licence}</Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          label="Programmes de master"
          name="masters"
          valuePropName="checked"
          className="checkbox-title"
        >
          <Checkbox.Group>
            {masters.map((master) => (
              <Checkbox key={master} value={master}>{master}</Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          label="Quand souhaitez vous intégrer la formation ?"
          name="integretion"
          style={{
            maxWidth: !screens.sm ? '100%' : '49%'
          }}
        >
          <Select allowClear placeholder="">
            <Select.Option value="09/23">Septembre 2023</Select.Option>
            <Select.Option value="09/24">Septembre 2024</Select.Option>
            <Select.Option value="09/25">Septembre 2025</Select.Option>
            <Select.Option value="09/26">Septembre 2026</Select.Option>
            <Select.Option value="09/27">Septembre 2027</Select.Option>
            <Select.Option value="09/28">Septembre 2028</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label=""
          name="autorisation"
          valuePropName="checked"
          rules={[{ required: true, message: 'Champ obligatoire' }]}
          style={{
            flex: '1 1 100%'
          }}
        >
          <Checkbox>
            J'autorise Sorbonne Université Abou Dabi à utiliser mes données
            personnelles à des fins de marketing liées à ses activités et
            services, en particulier pour l’envoi d’informations concernant les
            opportunités de formation et des posts en rapport avec mon intérêt.
          </Checkbox>
        </Form.Item>

        <Form.Item
          style={{
            flex: '1 1 100%',
            textAlign: 'center'
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{
              textTransform: 'uppercase',
              fontSize: 16,
              fontWeight: 700,
              padding: '0 120px'
            }}
          >
            Envoyer
          </Button>
        </Form.Item>
      </Form>
      <style jsx global>{`
      .form__container {
        padding: 0 32px 32px;
      }
        .form__header {
          padding: 8px;
        }
        .form__header h1 {
          font-size: 32px;
          font-weight: 900;
          color: #082268 !important;
        }
        .form__header p {
          font-size: 15px;
          color: #082268 !important;
        }

        .form__header p span {
          font-weight: 700;
        }
        .ant-form {
          display: flex;
          flex-wrap: wrap;
        }
        .ant-form > .ant-form-item {
          flex: 1 1 ${!screens.sm ? '100%' : '48%'};
          margin: 0 8px 16px;
        }
        label {
          font-size: 12px !important;
          line-height: 1;
          color: #082268 !important;
          font-weight: 500;
        }

        .formation {
          font-size: 14px !important;
          font-weight: 500;
          line-height: 1;
          color: #082268 !important;
          padding: 8px;
        }
        .ant-form-item-label {
          padding: 0 !important;
        }

        .ant-radio-inner::after {
          background-color: #082268 !important;
        }

        .ant-radio-checked .ant-radio-inner {
          border-color: #082268 !important;
        }

        .checkbox-title .ant-form-item-label label {
          font-size: 14px !important;
          color: #082268 !important;
          font-weight: 900;
          margin: 0 0 16px;
        }
        .ant-input[type='text'],
        .ant-select-selector,
        .ant-picker {
          color: #082268 !important;
          border: 2px solid #082268 !important;
          border-radius: 4px !important;
          width: 100% !important;
        }

        .ant-form-item-has-error input[type='text'] {
          border: 2px solid #f5222d !important;
        }

        .ant-form-item-explain-error {
          font-size: 12px;
          # display: none !important;
        }

        .ant-checkbox-group {
          display: flex;
          flex-direction: column;
        }

        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;
          line-height: 1.2;
          margin: 0 0 12px !important;
        }

        .ant-checkbox-inner {
          border-radius: 50%;
          border: 2px solid #082268 !important;
          width: 20px;
          height: 20px;
        }
        .form__error p {
          color: red !important;
        }
      `}</style>
    </div>
  );
};

export default InscriptionForm;
