import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { brandSettingState } from '../../recoil/brandsetting';
import { liveIdToUpdateState } from '../../recoil/playlist';
import Theme from '../../theme';
import { getContentParams } from '../../utils/functions/contentParams';
import useHeaders from '../../utils/hooks/useHeaders';
import { GET_UPDATED_LIVE } from '../../utils/subscription/live';
import { GET_UPDATED_LIVE_PLAYLIST } from '../../utils/subscription/playlist';
import Confidentiality from '../Confidentiality';
import Head from '../Head';
import LoaderSpin from '../LoaderSpin';
import Main from '../Main';
import { parseCookies } from 'nookies';

import { useTranslation } from 'next-i18next';
import { getBrandSettingType } from '../../utils/functions/brandSetting';
import CustomError from '../CustomError';
import Custom404 from '../../pages/404';
import useShouldBlockAccess from '../../utils/hooks/useShouldBlockAccess';
import Inscription from '../../pages/inscription';
import Form from '../Form';
import { sessionState } from '../../recoil/email';
import { Layout } from 'antd';
import Header from '../Header';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { redirect } from 'next/dist/server/api-utils';

const Page = function ({
  contentFromServer,
  errorFromServer,
  isEmbed = false,
  liveId
}) {
  const [content, setContent] = useState(contentFromServer);
  const [error, setError] = useState();
  const [brandSetting, setBrandSetting] = useRecoilState(brandSettingState);
  const [layoutStyle, setLayoutStyle] = useState();

  const screens = useBreakpoint();

  const router = useRouter();

  const email = parseCookies()['email'];

  const [session, setSession] = useRecoilState(sessionState);

  const { t } = useTranslation();

  const restrictedDomains =
    content?.embedSetting?.restrictedDomains?.map((domain) => domain.domain) ||
    error?.restrictedDomains ||
    [];
  const privacyType =
    content?.embedSetting?.privacyType || error?.embedPrivacy || 'public';
  const shouldBlockAccess = useShouldBlockAccess(
    isEmbed,
    privacyType,
    restrictedDomains
  );

  // https://nextjs.org/docs/routing/dynamic-routes#caveats
  // to note, while next is re-hydrating, query from router is empty
  const { mediaId, playlistId } = router.query;
  const noContent = !mediaId && !liveId && !playlistId && !error;

  const { query, contentId, contentType } = getContentParams(
    mediaId,
    liveId,
    playlistId
  );

  // re-hydrate next with new data
  const {
    data,
    error: apolloError,
    subscribeToMore
  } = useQuery(query, {
    variables: {
      mediaId,
      liveId,
      playlistId
    },
    context: {
      headers: useHeaders()
    },
    skip: noContent
  });

  const setRecoilLiveIdToUpdateState = useSetRecoilState(liveIdToUpdateState);
  console.log('session', session);

  useEffect(() => {
    email && setSession(email);
  }, [email]);

  // fonction qui retourn pagesetting ou embedsetting selon si c'est un embed ou non et si il y a des pagesetting ou non
  useEffect(() => {
    setBrandSetting((prev) => ({
      ...prev,
      ...getBrandSettingType(content, isEmbed)
    }));
  }, [content]);

  // LIVE SUBSCRIPTIONS
  useEffect(() => {
    if (!subscribeToMore || !liveId || noContent || router.isFallback) return;
    // the try catch is to avoid error with next.js fast refresh
    // https://github.com/apollographql/apollo-client/issues/6437
    try {
      subscribeToMore({
        document: GET_UPDATED_LIVE,
        variables: {
          id: liveId
        },
        updateQuery: (prev, { subscriptionData }) => {
          console.log('subscriptionData', subscriptionData);
          if (!subscriptionData) return prev;
          return {
            ...prev,
            live: { ...prev.live, ...subscriptionData.data.live }
          };
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [subscribeToMore]);

  // PLAYLIST SUBSCRIPTIONS
  useEffect(() => {
    if (!subscribeToMore || !playlistId || noContent || router.isFallback)
      return;
    // the try catch is to avoid error with next.js fast refresh
    // https://github.com/apollographql/apollo-client/issues/6437
    try {
      subscribeToMore({
        document: GET_UPDATED_LIVE_PLAYLIST,
        variables: {
          playlistId
        },
        updateQuery: (prev, { subscriptionData }) => {
          setRecoilLiveIdToUpdateState(subscriptionData.data.playlistLive.id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [subscribeToMore]);

  useEffect(() => {
    const dataRecevied = data && data[contentType];
    if (!dataRecevied) return;
    setContent(dataRecevied);
    setError(null);
  }, [data, router]);

  useEffect(() => {
    if (!apolloError) return;
    console.log('apolloError', apolloError);
    console.log('errorFromServer, ', errorFromServer);
    setError(apolloError?.graphQLErrors[0].extensions);
  }, [apolloError]);

  useEffect(() => {
    const styleTmp = {
      //overflow: screens.sm ? 'inherit' : 'hidden',
      //minHeight: screens.sm ? '100vh' : 'inherit'
    };
    if (!screens.sm) {
      if (CSS.supports('max-height', '100svh')) {
        //styleTmp.height = '100svh';
      } else {
        //styleTmp.height = '100vh';
      }
    }
    setLayoutStyle(styleTmp);
  }, [screens]);

  if (shouldBlockAccess) {
    return (
      <CustomError
        errorCode={t('error')}
        errorMessage={t('error-iframe-message')}
      />
    );
  }

  // while the next is fetching the data
  // show the loader
  if (router.isFallback) {
    return <LoaderSpin />;
  }
  // if playlist is empty
  if (playlistId && content?.content?.length === 0) {
    return <Custom404 />;
  }

  // if the content doesn't exist
  // and if the content is not protected (if the content is protected, no content is returned)
  if (noContent) {
    return (
      <CustomError
        errorCode={t('error-404')}
        errorMessage={t('error-404-message')}
      />
    );
  }

  // if the content is not published
  if (
    (mediaId || playlistId) &&
    content &&
    !content?.isPublished &&
    !router.query.allowUnpublished
  ) {
    return (
      <CustomError
        errorCode={t('error-404')}
        errorMessage={t('error-404-message')}
      />
    );
  }

  // If live status is Archived
  if (content?.status === 'Archived') {
    return (
      <CustomError
        errorCode={t('error-404')}
        errorMessage={t('error-404-message')}
      />
    );
  }

  if (error?.code === 'BAD_USER_INPUT') {
    return (
      <CustomError
        errorCode={t('error-404')}
        errorMessage={t('error-404-message')}
      />
    );
  }

  // hack to avoid the blinking privacy form when cookie or token is stored in cookie (client side)
  if (errorFromServer && !error && !content) {
    return <LoaderSpin />;
  }

  // if the content is protected
  if (!session) {
    return (
      <Layout
        style={{
          maxWidth: '1100px',
          margin: '0 auto',
          ...layoutStyle
        }}
        data-cy="layout"
      >
        <img
          src="/SUAB-header-formulaire.png"
          alt="hero"
          style={{ width: '100%' }}
        />
        {/* <Header /> */}
        <div
          className="subheader"
          style={{
            background: '#e43c2f'
          }}
        >
          <p>
            <span>Sorbonne Université Abou Dabi (SUAD)</span> est l'une des
            principales universités des Émirats Arabes Unis (EAU) qui offre un
            large éventail de Licences et Masters dans divers domaines, comme
            les sciences humaines, les sciences, le droit et les affaires.{' '}
            <span>Sorbonne Université Abou Dabi</span> bénéficie de plus de 7
            siècles d’excellence académique de la <span>Sorbonne Paris</span>.
          </p>
          <p>
            Vous envisagez de poursuivre ou reprendre vos études supérieures à
            l’international, venez étudier à{' '}
            <span>Sorbonne Université Abou Dabi</span> sur un campus
            ultra-moderne.
          </p>
          <p>
            Nous vous proposons le{' '}
            <span>Samedi 3 juin prochain à 11H00 (GMT)</span> un événement
            digital pour vous présenter{' '}
            <span>Sorbonne Université Abou Dabi</span>, le campus, les filières,
            la vie étudiante et les modalités d’inscription et de financement.
          </p>
          <div className="content">
            <h2>
              Rejoignez 7 siècles <br />
              d'excellence académique
            </h2>
            <div className="border">
              <p>
                <span>
                  Horaires de l’événement en heure locale en fonction des pays :
                </span>
                <br />
                Burkina-Faso, Côte d’Ivoire, Mali, Sénégal, Togo : 11H00
                <br />
                Bénin, Niger : 12H00
              </p>
            </div>
          </div>
        </div>
        <Form />
        <Theme accentColor={error?.contentColor} />
        <style jsx>{`
        .subheader {
          background: #e43c2f;
          padding: 24px;
        }
        .subheader p {
          color: #fff;
          font-size: 16px;
          line-height: 1.2;
          font-weight: 500
        }

        .subheader p span {
          font-weight: 700
        }

        .subheader .content  {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: ${!screens.sm ? 'column' : 'row'};
          margin-top: 32px;
        }

        .subheader .content h2 {
          font-size 24px;
          line-height 1.2;
          margin: 0 0 24px;
          text-align: ${!screens.sm ? 'center' : 'left'}
        }

        .subheader .content .border {
          font-size: 16px;
          border: 1px solid #fff;
          border-radius: 4px;
          padding: 8px;
        }
        `}</style>
      </Layout>
    );
  }

  return (
    <>
      <Head
        media={mediaId ? content : null}
        live={liveId ? content : null}
        playlist={playlistId ? content : null}
        locale={router.locale}
      />
      <Main
        media={mediaId ? content : null}
        live={liveId ? content : null}
        playlist={playlistId ? content : null}
        isEmbed={isEmbed}
      />
      <Theme />
    </>
  );
};

export default Page;
